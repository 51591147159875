import React from "react";
import Layout from "../../components/Layout";
import ScaleIcon from "../../components/icons/scale-icon";
import { Link } from "gatsby";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section is-medium has-text-centered">
      <div className="container">
        <div className="content">
          <div
            className="is-justify-content-center"
            style={{
              height: "230px",
              display: "flex",
            }}
          >
            <ScaleIcon />
          </div>
          <h1 className="is-size-1">Thank you!</h1>
          <p className="is-size-5">
            We've received your message and will be in touch with you as soon as
            we can.
          </p>
          <Link to="/">Return to the Home Page</Link>
        </div>
      </div>
    </section>
  </Layout>
);
